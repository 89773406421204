<script setup lang="ts">
import JamBaseCollapseTrigger from './JamBaseCollapseTrigger.vue';
import type { JamBaseLinkVariant } from './JamBaseLink.vue';

const props = defineProps({
    open: { default: false, type: Boolean },
    triggerClasses: { default: '', type: String },
    triggerContentClasses: { default: '', type: String },
    triggerContentGap: { default: 'gap-6', type: String },
    triggerPosition: {
        default: 'bottom',
        type: String as () => 'top' | 'bottom',
    },
    triggerText: { default: 'Detaillierte Eingabe', type: String },
    triggerVariant: {
        default: 'green',
        type: String as PropType<JamBaseLinkVariant>,
    },
    triggerWrapperClasses: { default: '', type: String },
});

const emit = defineEmits(['update:isOpen']);

const transitionDuration = 200;

const id = useId();
const isOpen = ref(props.open);
const opacity = ref('0');
const gridTemplateRows = ref('0fr');
const visibility = ref<'visible' | 'hidden'>('hidden');
const overflow = ref('hidden');

const updateCollapse = () => {
    opacity.value = isOpen.value ? '1' : '0';
    gridTemplateRows.value = isOpen.value ? '1fr' : '0fr';
    if (isOpen.value) {
        visibility.value = 'visible';
        setTimeout(() => {
            overflow.value = 'visible';
        }, transitionDuration);
    } else {
        overflow.value = 'hidden';
        setTimeout(() => {
            visibility.value = 'hidden';
        }, transitionDuration);
    }
};

onMounted(() => {
    updateCollapse();
});

watch(
    () => props.open,
    () => {
        isOpen.value = props.open;
        updateCollapse();
    },
);

watch(isOpen, () => {
    updateCollapse();
    emit('update:isOpen', isOpen.value);
});
</script>

<template>
    <div
        class="flex flex-col"
        :class="[isOpen ? triggerContentGap : 'gap-0']"
        :style="`transition: gap ${transitionDuration}ms ease ${
            isOpen ? '0' : transitionDuration
        }ms`"
    >
        <template v-if="triggerPosition === 'top'">
            <JamBaseCollapseTrigger
                :id="id"
                :open="isOpen"
                :trigger-text="triggerText"
                :trigger-classes="triggerClasses"
                :trigger-wrapper-classes="triggerWrapperClasses"
                :trigger-content-classes="triggerContentClasses"
                :trigger-variant="triggerVariant"
                @update:is-open="isOpen = $event"
            >
                <template #text>
                    <slot name="text" />
                </template>
                <template #trigger>
                    <slot name="trigger" :is-open="isOpen" />
                </template>
            </JamBaseCollapseTrigger>
        </template>
        <div
            :id="id"
            :aria-expanded="isOpen"
            class="grid"
            :style="{
                visibility,
                opacity,
                gridTemplateRows,
                transition: `grid-template-rows ${transitionDuration}ms ease ${
                    isOpen ? '0' : transitionDuration
                }ms, opacity ${transitionDuration}ms ease ${
                    !isOpen ? '0' : transitionDuration
                }ms`,
            }"
        >
            <div
                :style="{
                    overflow,
                }"
            >
                <slot />
            </div>
        </div>
        <template v-if="triggerPosition === 'bottom'">
            <JamBaseCollapseTrigger
                :id="id"
                :open="isOpen"
                :trigger-text="triggerText"
                :trigger-classes="triggerClasses"
                :trigger-wrapper-classes="triggerWrapperClasses"
                :trigger-content-classes="triggerContentClasses"
                :trigger-variant="triggerVariant"
                @update:is-open="isOpen = $event"
            >
                <template #text>
                    <slot name="text" />
                </template>
                <template #trigger>
                    <slot name="trigger" :is-open="isOpen" />
                </template>
            </JamBaseCollapseTrigger>
        </template>
    </div>
</template>
