<script setup lang="ts">
import type { JamBaseLinkVariant } from './JamBaseLink.vue';

const props = defineProps({
    id: { required: true, type: String },
    open: { default: false, type: Boolean },
    triggerClasses: { default: '', type: String },
    triggerContentClasses: { default: '', type: String },
    triggerText: { default: '', type: String },
    triggerVariant: {
        default: 'green',
        type: String as PropType<JamBaseLinkVariant>,
    },
    triggerWrapperClasses: { default: '', type: String },
});

const emit = defineEmits(['update:isOpen']);

const isOpen = ref(props.open);

watch(
    () => props.open,
    () => {
        isOpen.value = props.open;
    },
);

watch(isOpen, () => {
    emit('update:isOpen', isOpen.value);
});
</script>

<template>
    <div class="flex justify-between" :class="triggerWrapperClasses">
        <slot name="text"></slot>

        <button
            class="flex"
            :class="triggerClasses"
            type="button"
            :aria-controls="id"
            :aria-expanded="isOpen"
            @click="isOpen = !isOpen"
        >
            <slot name="trigger" :is-open="isOpen">
                <JamBaseLink
                    as="span"
                    has-trailing-icon
                    :flip-trailing-icon="isOpen"
                    :variant="triggerVariant"
                    class="flex items-center gap-3"
                    :class="triggerContentClasses"
                >
                    <span v-if="isOpen">
                        {{ triggerText || 'Einklappen' }}
                    </span>
                    <span v-else>
                        {{ triggerText || 'Ausklappen' }}
                    </span>
                </JamBaseLink>
            </slot>
        </button>
    </div>
</template>
